<template>
  <div>
    <button
      variant="success"
      v-b-modal.modal-1
      class="btn btn-sm btn-success"
      type="button"
    >
      Add Cottage
    </button>
    <div>
      <b-modal id="modal-1" title="Register a Cottage" size="xl" hide-footer>
        <b-alert variant="success" v-if="success" show> {{ success }}</b-alert>
        <b-form @submit.prevent="submitForm" enctype="multipart/form-data">
          <div class="row">
            <div class="col-md-6">
              <b-form-group
                id="input-group-1"
                label="Cottage Name:"
                label-for="input-1"
              >
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <b-form-input
                    id="input-1"
                    type="text"
                    placeholder="Enter the Cottage Name"
                    v-model="form.name"
                    required
                  ></b-form-input>
                  <span class="error">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group
                id="input-group-2"
                label="Ameneties:"
                label-for="input-2"
              >
                <b-form-checkbox-group
                  id="checkbox-group-2"
                  v-model="form.amenities"
                  name="flavour-2"
                >
                  <b-form-checkbox
                    v-model="form.amenities"
                    v-for="(item, index) in amenities"
                    :key="index"
                    :value="item._id"
                    >{{ item.name }}</b-form-checkbox
                  >
                </b-form-checkbox-group>
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <b-form-group
                id="input-group-1"
                label="Description:"
                label-for="input-1"
              >
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <b-form-textarea
                    id="input-2"
                    placeholder="Enter description..."
                    rows="9"
                    max-rows="9"
                    v-model="form.description"
                  ></b-form-textarea>
                  <span class="error">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <b-form-group
                id="input-group-1"
                label="Credits:"
                label-for="input-1"
              >
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <ul v-for="(credit, index) in form.credits" :key="index">
                    <li>
                      <div class="row">
                        <div class="col-2 mt-2">
                          {{ credit.name }}
                        </div>
                        <div class="col-10">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <b-form-input
                              class="sm"
                              id="input-1"
                              v-model="credit.points"
                              type="text"
                              required
                            ></b-form-input>
                            <span class="error">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <span class="error">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </div>
            <div class="row">
              <div class="col-md-6">
                <b-form-group
                  id="input-group-1"
                  label="Service Charges  / day:"
                  label-for="input-1"
                >
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-form-input
                      id="input-1"
                      type="number"
                      v-model="form.service_charge"
                      placeholder="Enter Service Charges"
                      required
                    ></b-form-input>
                    <span class="error">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group
                  id="input-group-1"
                  label="No. of Cottage(s)"
                  label-for="input-1"
                >
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-form-input
                      id="input-1"
                      type="number"
                      placeholder="Enter Service Charges"
                      v-model="form.total_rooms"
                      required
                    ></b-form-input>
                    <span class="error">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>
              </div>
            </div>
          </div>
          <b-button size="sm" variant="success" @click="submitForm()">
            Register
          </b-button>
        </b-form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "RoomsCreate",
  data() {
    return {
      tariffs: ["weekends", "weekdays", "holidays"],
      credits: ["weekends", "weekdays", "holidays"],
      amenities: ["Television", "Air conditioner", "Kitchen"],
      success: false,
      form: {
        service_charge: 0,
        name: "",
        description: "",
        amenities: [],
        total_rooms: 0,
        credits: [
          {
            type: 1,
            name: "weekends",
            points: 0,
          },
          {
            type: 2,
            name: "weekdays",
            points: 0,
          },
          {
            type: 3,
            name: "holidays",
            points: 0,
          },
        ],
        tariffs: [
          {
            type: 1,
            name: "weekends",
            points: 0,
          },
          {
            type: 2,
            name: "weekdays",
            points: 0,
          },
          {
            type: 3,
            name: "holidays",
            points: 0,
          },
        ],
      },
    };
  },
  mounted() {
    this.getAmenities();
  },
  methods: {
    submitForm() {
      console.log(this.form);
      let resortID = this.$route.params.id;
      this.form._projectResortID = resortID;
      axios.post("project-resort-rooms", this.form).then((resp) => {
        console.log(resp);
        if (resp.data.success) {
          alert(resp.data.message);
          this.$emit("getRooms");
          window.setTimeout(() => {
            this.$refs["modal-1"].hide();
          }, 3000);
        }
      });
    },
    getAmenities() {
      axios.get("amenities").then((res) => {
        console.log(res.data);
        this.amenities = res.data.data;
      });
    },
  },
};
</script>
