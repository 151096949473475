<template>
  <div class="mt-3 p-2">
    <b-container fluid class="mb-2">
      <h5>Farm Retreats</h5>
      <b-row no-gutters>
        <b-col md="8">
          <b-card class="farm-retreats-details border-0">
            <b-card-text>
              <table class="table table-sm">
                <tr>
                  <th>Name</th>
                  <td>{{ details._projectID.name }}</td>
                </tr>
                <tr>
                  <th>Location</th>
                  <td>{{ details._projectID.location }}</td>
                </tr>
                <tr>
                  <th>Description</th>
                  <td>{{ details.description }}</td>
                </tr>

                <tr>
                  <th>Ammenities</th>
                  <td>
                    <span
                      v-for="(amenity, index) in details.amenities"
                      :key="index"
                    >
                      <img
                        :src="amenity._amenitiesID.image"
                        alt=""
                        width="30px"
                        height="30px"
                      />
                      {{ amenity._amenitiesID.name }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>Credits</th>
                  <td>
                    <table class="table table-sm credits-table mt-2">
                      <thead>
                        <tr>
                          <th>Land Extent</th>
                          <th>Credits</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(credit, index) in details.credits"
                          :key="index"
                        >
                          <td>{{ credit.acre }}</td>
                          <td>{{ credit.points }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </table>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col md="4" class="text-right">
          <img
            class="img-thumbnail"
            width="300px"
            alt=""
            :src="details.image"
          />
        </b-col>
      </b-row>
      <b-row no-gutters class="mb-3">
        <b-col md="12" class="text-right">
          <CreateRooms @getRooms="GetHotelRoomsData" />
        </b-col>
      </b-row>
      <b-table
        id="my-table"
        class="dataTable rounded-left"
        :outlined="true"
        :items="rooms"
        :fields="fields"
        head-variant="none"
        table-variant="none"
        :caption-top="true"
        :responsive="true"
        show-empty
      >
        <template #empty="scope">
          <h4>{{ scope.emptyText }}</h4>
        </template>
        <template #cell(#)="data">{{ data.index + 1 }}</template>
        <template #cell(amenities)="row">
          <span v-for="(amenity, index) in row.item.amenities" :key="index">
            {{ amenity._amenitiesID.name }}
          </span>
        </template>
        <template #cell(credits)="row">
          <span v-for="(credit, index) in row.item.credits" :key="index">
            <span v-if="credit.type == 1">Weekends</span>
            <span v-if="credit.type == 2">Weekdays</span>
            <span v-if="credit.type == 3">Holidays</span>
            {{ credit.points }}
            <br />
          </span>
        </template>
        <template #cell(action)="row">
          <EditRooms
            :data="row.item"
            :index="row.index"
            @getRooms="GetHotelRoomsData"
          />
        </template>
        <template #table-caption>Cottage List</template>
      </b-table>

      <div class="row">
        <!-- <div class="col-md-6">
          <h5>Cottage List</h5>
        </div> -->

        <!-- <div class="col-md-12">
          <table class="table table-sm">
            <thead class="thead-dark">
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Cottage Description</th>
                <th>Cottage Ameneties</th>
                <th>Credits</th>
                <th>Service Charges</th>
                <th>Total Cottage</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="rooms.length > 0">
                <tr v-for="(room, index) in rooms" :key="index">
                  <td>{{ 1 + index }}</td>
                  <td>{{ room.name }}</td>
                  <td>{{ room.descriptions }}</td>
                  <td>
                    <span
                      v-for="(amenity, index) in room.amenities"
                      :key="index"
                    >
                      {{ amenity._amenitiesID.name }}
                    </span>
                  </td>
                  <td>
                    <span v-for="(credit, index) in room.credits" :key="index">
                      <span v-if="credit.type == 1">Weekends</span>
                      <span v-if="credit.type == 2">Weekdays</span>
                      <span v-if="credit.type == 3">Holidays</span>
                      {{ credit.points }}
                      <br />
                    </span>
                  </td>

                  <td>{{ room.service_charges }}</td>
                  <td>{{ room.room_counts }}</td>
                  <td>
                    <b-dropdown
                      id="dropdown-1"
                      text="Actions"
                      class="m-md-2"
                      size="sm"
                    >
                      <EditRooms
                        :data="room"
                        :index="index"
                        @getRooms="GetHotelRoomsData"
                      />
                    </b-dropdown>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="8" class="text-center">No Record Found</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div> -->
      </div>
    </b-container>
  </div>
</template>

<script>
import CreateRooms from "@/components/projects/resorts/CreateRoom";
import EditRooms from "@/components/projects/resorts/EditRoom";
import axios from "axios";

export default {
  components: {
    CreateRooms,
    EditRooms,
  },
  name: "ResortView",
  data() {
    return {
      details: "",
      rooms: [],
      fields: [
        "#",
        {
          key: "name",
          label: "Name",
        },
        {
          key: "descriptions",
          label: "Cottage Description",
        },
        {
          key: "amenities",
          label: "Cottage Ameneties",
        },
        {
          key: "credits",
          label: "Credits",
        },
        {
          key: "service_charges",
          label: "Service Charges",
        },
        {
          key: "room_counts",
          label: "Total Cottage",
        },
        "action",
      ],
    };
  },
  mounted() {
    this.getData();
    this.GetHotelRoomsData();
  },
  methods: {
    getData() {
      let resortId = this.$route.params.id;
      axios.get("project-resorts/" + resortId).then((resp) => {
        console.log(resp);
        this.details = resp.data.data;
        console.log(this.details.rooms);
      });
    },
    GetHotelRoomsData() {
      let resortId = this.$route.params.id;
      axios.get("project-resort-rooms/" + resortId).then((resp) => {
        console.log(resp);
        this.rooms = resp.data.data;
        console.log(this.details.rooms);
      });
    },
  },
};
</script>

<style scoped>
.farm-retreats-details .card {
  border-radius: 11px;
}

.farm-retreats-details .card-body {
  background-color: #dcffd9;
  border-radius: 11px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.table > tr:first-child > td,
.table > tr:first-child > th {
  border: none;
}

.credits-table thead tr th {
  background-color: #e6e6e6;
  height: 51px;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 3.5%;
  font-family: "Poppins", sans-serif;
  padding-top: 14px;
  padding-bottom: 14px;
}

.credits-table tbody {
  background-color: #e8f8e6;
}

.credits-table {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

/deep/ .dataTable {
  margin-left: 2px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

/deep/ .dataTable thead {
  background-color: #e6e6e6;
  height: 51px;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 3.5%;
  font-family: "Poppins", sans-serif;
  padding-top: 14px;
  padding-bottom: 14px;
}

/deep/ .dataTable {
  border-radius: 10px;
  text-align: center;
  margin-bottom: 21.5px;
  margin-left: 2px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}
/deep/ .dataTable tr td {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  letter-spacing: 3.5%;
  line-height: 25.5px;
  font-weight: 400;
  padding-top: 12px;
  padding-bottom: 12px;
}

/deep/ .dataTable caption {
  border-radius: 10px 10px 0 0;
  height: 64px;
  width: 100%;
  background-color: #dcffd9;
  color: #000;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 3.5%;
  padding-left: 27px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 25px;
}
</style>
