var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-1",modifiers:{"modal-1":true}}],staticClass:"btn btn-sm btn-success",attrs:{"variant":"success","type":"button"}},[_vm._v(" Add Cottage ")]),_c('div',[_c('b-modal',{attrs:{"id":"modal-1","title":"Register a Cottage","size":"xl","hide-footer":""}},[(_vm.success)?_c('b-alert',{attrs:{"variant":"success","show":""}},[_vm._v(" "+_vm._s(_vm.success))]):_vm._e(),_c('b-form',{attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"id":"input-group-1","label":"Cottage Name:","label-for":"input-1"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-1","type":"text","placeholder":"Enter the Cottage Name","required":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"id":"input-group-2","label":"Ameneties:","label-for":"input-2"}},[_c('b-form-checkbox-group',{attrs:{"id":"checkbox-group-2","name":"flavour-2"},model:{value:(_vm.form.amenities),callback:function ($$v) {_vm.$set(_vm.form, "amenities", $$v)},expression:"form.amenities"}},_vm._l((_vm.amenities),function(item,index){return _c('b-form-checkbox',{key:index,attrs:{"value":item._id},model:{value:(_vm.form.amenities),callback:function ($$v) {_vm.$set(_vm.form, "amenities", $$v)},expression:"form.amenities"}},[_vm._v(_vm._s(item.name))])}),1)],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"id":"input-group-1","label":"Description:","label-for":"input-1"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"input-2","placeholder":"Enter description...","rows":"9","max-rows":"9"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"id":"input-group-1","label":"Credits:","label-for":"input-1"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_vm._l((_vm.form.credits),function(credit,index){return _c('ul',{key:index},[_c('li',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 mt-2"},[_vm._v(" "+_vm._s(credit.name)+" ")]),_c('div',{staticClass:"col-10"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"sm",attrs:{"id":"input-1","type":"text","required":""},model:{value:(credit.points),callback:function ($$v) {_vm.$set(credit, "points", $$v)},expression:"credit.points"}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])])])}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"id":"input-group-1","label":"Service Charges  / day:","label-for":"input-1"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-1","type":"number","placeholder":"Enter Service Charges","required":""},model:{value:(_vm.form.service_charge),callback:function ($$v) {_vm.$set(_vm.form, "service_charge", $$v)},expression:"form.service_charge"}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"id":"input-group-1","label":"No. of Cottage(s)","label-for":"input-1"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-1","type":"number","placeholder":"Enter Service Charges","required":""},model:{value:(_vm.form.total_rooms),callback:function ($$v) {_vm.$set(_vm.form, "total_rooms", $$v)},expression:"form.total_rooms"}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)])]),_c('b-button',{attrs:{"size":"sm","variant":"success"},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v(" Register ")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }