<template>
  <div>
    <b-button
      size="sm"
      variant="success"
      @click="$bvModal.show('modal-edit-' + index)"
      class="edit-icon shadow-none"
    >
      <i class="fa fa-edit"></i>
    </b-button>

    <div>
      <b-modal
        :id="'modal-edit-' + index"
        title="Edit a Cottage"
        size="xl"
        hide-footer
      >
        <b-alert variant="success" v-if="success" show> {{ success }}</b-alert>
        <b-form @submit.prevent="submitForm" enctype="multipart/form-data">
          <div class="row">
            <div class="col-md-6">
              <b-form-group
                id="input-group-1"
                label="Name:"
                label-for="input-1"
              >
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <b-form-input
                    id="input-1"
                    type="text"
                    v-model="form.name"
                    required
                  ></b-form-input>
                  <span class="error">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group
                id="input-group-2"
                label="Ameneties:"
                label-for="input-2"
              >
                <b-form-checkbox-group
                  id="checkbox-group-2"
                  v-model="form.amenities"
                  name="flavour-2"
                >
                  <b-form-checkbox
                    v-model="form.amenities"
                    v-for="(item, index) in amenities"
                    :key="index"
                    :value="item._id"
                    >{{ item.name }}</b-form-checkbox
                  >
                </b-form-checkbox-group>
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <b-form-group
                id="input-group-1"
                label="Description:"
                label-for="input-1"
              >
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <b-form-textarea
                    id="input-2"
                    placeholder="Enter description..."
                    rows="9"
                    max-rows="9"
                    v-model="form.description"
                  ></b-form-textarea>
                  <span class="error">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <b-form-group
                id="input-group-1"
                label="Credits:"
                label-for="input-1"
              >
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <ul v-for="(credit, index) in form.credits" :key="index">
                    <li>
                      <div class="row">
                        <div class="col-2 mt-2">
                          {{ credit.name }}
                        </div>
                        <div class="col-10">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <b-form-input
                              class="sm"
                              id="input-1"
                              v-model="credit.points"
                              type="text"
                              required
                            ></b-form-input>
                            <span class="error">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <span class="error">{{ errors[0] }}</span>
                </ValidationProvider>
              </b-form-group>
            </div>
            <div class="row">
              <div class="col-md-6">
                <b-form-group
                  id="input-group-1"
                  label="Service Charges:"
                  label-for="input-1"
                >
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-form-input
                      id="input-1"
                      type="number"
                      v-model="form.service_charge"
                      placeholder="Enter Service Charges"
                      required
                    ></b-form-input>
                    <span class="error">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group
                  id="input-group-1"
                  label="No. of Rooms:"
                  label-for="input-1"
                >
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <b-form-input
                      id="input-1"
                      type="number"
                      placeholder="Enter Service Charges"
                      v-model="form.total_rooms"
                      required
                    ></b-form-input>
                    <span class="error">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-form-group>
              </div>
            </div>
          </div>
          <b-button size="sm" variant="success" @click="submitForm()">
            Update
          </b-button>
        </b-form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "RoomsCreate",
  data() {
    return {
      tariffs: ["weekends", "weekdays", "holidays"],
      credits: ["weekends", "weekdays", "holidays"],
      amenities: ["Television", "Air conditioner", "Kitchen"],
      success: false,
      form: {
        service_charge: 0,
        name: "",
        description: "",
        amenities: [],
        total_rooms: 0,
        credits: [
          {
            type: 1,
            name: "weekends",
            points: 0,
          },
          {
            type: 2,
            name: "weekdays",
            points: 0,
          },
          {
            type: 3,
            name: "holidays",
            points: 0,
          },
        ],
        tariffs: [
          {
            type: 1,
            name: "weekends",
            mrp: 0,
          },
          {
            type: 2,
            name: "weekdays",
            mrp: 0,
          },
          {
            type: 3,
            name: "holidays",
            mrp: 0,
          },
        ],
      },
    };
  },
  mounted() {
    this.getAmenities();
    this.initializeData();
  },
  props: ["data", "index"],
  methods: {
    initializeData() {
      let amenities = [];
      this.data.amenities.forEach((amenity) => {
        amenities.push(amenity._amenitiesID._id);
      });
      this.form.credits.forEach((credit) => {
        console.log(credit);
      });
      this.form.tariffs.forEach((tariff) => {
        console.log(tariff);
      });
      this.form.name = this.data.name;
      this.form.service_charge = this.data.service_charges;
      this.form.description = this.data.descriptions;
      this.form.amenities = amenities;
      this.form.total_rooms = this.data.room_counts;
      this.form.credits = this.data.credits;
      this.form.tariffs = this.data.tariffs;
      this.form._id = this.data._id;
    },
    submitForm() {
      console.log(this.form);
      let vm = this;
      let resortID = this.$route.params.id;
      this.form._projectResortID = resortID;
      axios.put("project-resort-rooms/" + resortID, this.form).then((resp) => {
        console.log(resp);
        if (resp.data.success) {
          alert(resp.data.message);
          this.$emit("getRooms");

          window.setTimeout(() => {
            let id = "modal-edit-" + this.index;
            console.log(id);
            vm.$refs[id].hide();
          }, 3000);
        }
      });
    },
    getAmenities() {
      axios.get("amenities").then((res) => {
        console.log(res.data);
        this.amenities = res.data.data;
      });
    },
  },
};
</script>

<style scoped>
.edit-icon {
  border: none;
  color: #68561d;
  background-color: #fff;
  margin: 0;
  padding: 0;
}

.fa-edit {
  height: 18px;
  width: 30px;
}
</style>
